<template>
<div>
	<el-dialog :visible.sync="dialogAddVisible" :close-on-click-modal="false" >
	    <el-form ref="form" :model="form" label-width="100px" prop="">
	       <el-form-item label="上级菜单" prop="parent_id">
				<el-cascader v-model="form.parent_id" ref="cascader" :options="selectList" :show-all-levels="false"
					:props="{value:'id',label:'name',checkStrictly: true}"
				 style="width: 400px;" @change="changeCascader"></el-cascader>
	        </el-form-item>
			
	        <el-form-item label="菜单名称" prop="name">
	            <el-input  v-model="form.name" style="width:400px;"></el-input>
	        </el-form-item>
	        <el-form-item label="展示图标" prop="icon">
	            <el-input v-model="form.icon" style="width:400px;"></el-input>
	        </el-form-item>
	           <el-form-item label="页面图标" prop="page_icon">
	              <el-upload 
	                style="width: 80px;height:80px;"
	                class="image-uploader"
	                :action="$uploadUrl" 
	                :show-file-list="false" 
	                :on-success="handleSuccessUpload"
	                :before-upload="handleBeforeUpload">
	                <img v-if="form.page_icon"  :src=$imgDomain+form.page_icon class="image" style="width: 80px;height:80px;">
	                <i v-else class="el-icon-plus" style="width:80px;height:80px;line-height:80px;"></i>
	               
	             </el-upload> 
	              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，推荐上传尺寸45*45</div>
	        </el-form-item>
	        <el-form-item label="菜单路径" prop="url">
	            <el-input v-model="form.url" style="width:400px;"></el-input>   
	        </el-form-item>
	         <el-form-item label="排序" popo="sort">
	            <el-input v-model="form.sort" style="width:400px;"></el-input>   
	        </el-form-item>
	        <el-form-item label="是否按钮" prop="btn_status">
	            <template>
	                <el-radio v-model="form.btn_status" :label="0" checked>否</el-radio>
	                <el-radio v-model="form.btn_status" :label="1">是</el-radio>
	            </template>
	        </el-form-item>
	        <el-form-item label="状态" popo="status">
	            <template>
	                <el-radio v-model="form.status" :label="0">禁用</el-radio>
	                <el-radio v-model="form.status" :label="1" >启用</el-radio>
	            </template>
	        </el-form-item>
	       <!--  <el-form-item label="接口权限" popo="api_status">
	            <template>
	                <el-radio v-model="form.api_status" :label="0">否</el-radio>
	                <el-radio v-model="form.api_status" :label="1" >是</el-radio>
	            </template>
	        </el-form-item> -->
			<el-form-item label="参与短链" popo="short_link_status">
			    <template>
			        <el-radio v-model="form.short_link_status" :label="0">否</el-radio>
			        <el-radio v-model="form.short_link_status" :label="1" >是</el-radio>
			    </template>
			</el-form-item> 
	        <el-form-item label="显示位置" popo="page_location_status">
	            <template>
	                <el-radio v-model="form.page_location_status" :label="0">不显示</el-radio>
	                <el-radio v-model="form.page_location_status" :label="1" >常用功能位置</el-radio>
	                <el-radio v-model="form.page_location_status" :label="2" >营销活动位置</el-radio>
					<el-radio v-model="form.page_location_status" :label="3" >固定资产模块-资产</el-radio>
					<el-radio v-model="form.page_location_status" :label="4" >固定资产模块-耗材</el-radio>
	            </template>
	            <p style="color:#C0C4CC;margin:0;">勾选后该菜单将展示在客户端首页对应位置</p>
	        </el-form-item>
	    </el-form>
	     <div slot="footer" class="dialog-footer">
	            <el-button @click="dialogAddVisible = false">取 消</el-button>
	            <el-button type="primary" @click="save()">确 定</el-button>
	        </div>
	</el-dialog>
</div>
</template>
<script>
export default {
	 inject:['reload'],
    data() {
        return {
			dialogAddVisible:false,
			form:{
			    id:'',
			    name:'',
			    parent_id:0,
			    btn_status:0,
			    status:1,
			    icon:'',
			    url:'',
			    sort:0,
			    api_status:0,
			    page_location_status:0,
				short_link_status:0
			},
			selectList:[],
			
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		showDialog(type,id){
			if(type == 'add'){
				this.form = {
			    id:'',
			    name:'',
			    parent_id:0,
			    btn_status:0,
			    status:1,
			    icon:'',
			    url:'',
			    sort:0,
			    api_status:0,
			    page_location_status:0,
				short_link_status:0
			},
				this.handleAdd(id)
			}else{
				this.handleEdit(id)
			}
		},
		
		//添加
		handleAdd(id){
			this.form.parent_id =  id
			let parent_id =  id;
			this.$api.get('Menu/add',{'parent_id':parent_id},res=>{
			    if(res.code == 200){
			        this.dialogAddVisible =  true
			        this.selectList = res.data
					console.log(this.selectList)
			         this.$nextTick(function(){
			            this.$refs["form"].resetFields()
			        })
			    }else{
			        this.$message.error()
			    }
			})
		},
		//编辑
		handleEdit(id){
			this.$api.get('menu/edit',{'id':id},res=>{
			    if(res.code == 200){
			        this.dialogAddVisible =  true
			        this.form = res.data.data
			        this.selectList =  res.data.menu_list
			    }else{
			        this.$message.error(res.msg)
			    }
			})
		},
		changeCascader(val){
			this.form.parent_id =  this.$refs.cascader.getCheckedNodes()[0]['data']['id']
		},
		handleBeforeUpload(file){   //上传前验证
		     this.$emit('handleBeforeUpload',file)
		 },
		 handleSuccessUpload(res){     //上传成功 返回文件地址
		     if(res.code == 200){
		         this.form.page_icon =  res.data.url
		     }else{
		         this.$message.error(res.msg)
		     }
		}, 
		
		//保存
		save(){
           let  url = 'Menu/add'
           if(this.form.id > 0){
               url = 'Menu/edit'
           }
            this.$api.post(url,this.form,res=>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                }else{
                    this.$message.error(res.msg)
                }
                this.$refs['form'].resetFields();
                this.reload()
              
                this.dialogAddVisible =  false
            })
       },
    },
}
</script>
<style scoped>

</style>