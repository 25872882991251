<template>
     <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p> 
        <div style="margin-bottom:10px;">
            <div class="search-form">
              <el-form ref="form" :inline="true"  :model="search_key" prop="" >
                <el-form-item label="选择菜单">
                    <el-select v-model="search_key.id" @change="seach" clearable >
                        <template v-for="item in topList" >
                            <el-option :key="item.id" v-if="item.parent_id==0" :label="item.name" :value="item.id"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
            </el-form>  
         </div>
            <el-button size="small" type="" @click="handleAdd()">添加</el-button>
        </div>
        <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
        
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column
            prop="name"
            label="菜单"
            sortable
            width="180">
            </el-table-column>
            <el-table-column
            prop="icon"
            label="图标"
            sortable
            width="180">
                <template slot-scope="scope">
                    <i :class="scope.row.icon"></i>
                </template>
            </el-table-column>
            <el-table-column
            label="页面图标">
                <template slot-scope="scope" v-if="scope.row.page_icon">
                    <img  :src=$imgDomain+scope.row.page_icon style="width:45px;height:45px;">
                </template>
            </el-table-column>
            <el-table-column prop="url" label="路由">
            </el-table-column>
            <el-table-column
            prop="status"
            label="状态">
            <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color:#67C23A;">启用</span>
                <span v-else style="color:#909399">禁用</span>
            </template>
            </el-table-column>
            <el-table-column
            prop="btn_status"
            label="是否按钮">
                <template slot-scope="scope">
                    <span v-if="scope.row.btn_status == 1">按钮</span>
                </template>
            </el-table-column>
            <el-table-column 
            prop="sort"
            label="排序"
            ></el-table-column>
            <el-table-column 
                width="230"
                fixed="right" 
                label='操作'>
                <template slot-scope="scope">
                    <!-- <el-button size="small" @click="handleEdit(scope.row)">编辑</el-button> -->
                    <el-button size="small" plain @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="small" type="primary" plain @click="handleAdd(scope.row.id)"><i class="el-icon-plus"></i>子级</el-button>
                    <el-button size="small" type='danger' plain @click="handleDel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
		<AddVue ref="childDialog"></AddVue>
    </div>
</template>
<script>
import AddVue from './Add.vue'
export default {
	 components:{
		AddVue 
	 },
    inject:['reload'],
   data() {
      return {
          search_key:{
              id:''
          },
          list:[],
          tableData:[],
          topList:[],
      }
   },
   created(){
       this.getData()
   },
   mounted(){
    //    console.log(this.$refs)
   },
   methods:{
       seach(val){
            var list = this.list
            let i=0
            for(i;i<list.length;i++){
                if(list[i]['id'] == val){
                   this.tableData =  [list[i]]
                }
            }
       },
       getData(){
        //    var params = {token:localStorage.token}
            var params =  null
           this.$api.get('Menu/index',{token:localStorage.token},res=>{
               if(res.code == 200){
                    this.tableData= this.list =  res.data.list??[]
                    this.topList =  res.data.top_list??[]
               }
           })
       },
     
       handleAdd(id=0){
		   this.$refs.childDialog.showDialog('add',id)
       },
        handleEdit(row){    //编辑菜单
			this.$refs.childDialog.showDialog('edit',row.id)
        },
       handleDel(row){  //删除菜单
             this.$alert('确定删除该菜单吗？','删除提示',{
                // confirmButtonText:'确定',
                showConfirmButton:true,
                showCancelButton:true,
                callback:action=>{
                    if(action == 'confirm'){
                        this.$api.delete('Menu/del',{'id':row.id},res=>{
                            if(res.code == 200){
                                this.$message.success(res.msg)
                                this.reload();
                            }
                        })
                     }else if(action =='cancel'){
                         this.reload()
                     }
                 }
            })
       },

   }

}
</script>
<style scoped lang="scss">
</style>